@import "src/styles/variables";
.clinicMsgCard {
    overflow: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    margin: 16px 40px 24px 40px;
    // min-width: 300px;
  
    justify-content: flex-start;
    
    // style={{display: "flex", flexDirection: "row", marginBottom: "24px", marginTop: "16px", marginRight: "40px", marginLeft: "40px"}}

    @media only screen and (max-width: $minScreen) {
      min-width: 300px;
      margin-left: 0;
    }
  
    @media only screen and (max-width: 667px) {
      min-width: 270px;
    }
  }

.colorFooter {
    color: white;
    background-color: #1C1F34;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
}

.messageBox {
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;

    width: 100%;

    height: 135px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 24px;
    
    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;

}

.messageBody {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 20px;

}