@import "src/styles/variables";

.displayCardContainer {

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    margin-bottom: 20px !important;

    .calendar {
        padding: 0px 0px 6px 0px;
    }

    .clipboard {
        padding: 0px 0px 10px 0px;
    }

    .diary-box {
        margin-left: -10px;

        p {
            margin-bottom: 0;
        }
    }

    h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    @media screen and (min-width: 1390px) {
        width: 75%;
    }
}
