@import "src/styles/variables";

.chatInputContainer {
  position: sticky;
  bottom: 24px;
  min-width: 300px;
}

.chatInput {
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.5) !important;
    border-color: rgba(102, 187, 106, 0.5) !important;
  }
}

.btnHover {
  background-color: white !important;

  &:hover {
    background-color: rgba(102, 187, 106, 0.5) !important;

    svg {
      g {
        rect {
          fill-opacity: 1;
          fill: white;
        }
      }
    }
  }

  &:active {
    background-color: #64A587 !important;
    box-shadow: 0 0 0 0.2rem rgba(102, 187, 106, 0.5) !important;
  }

  &:focus {
    background-color: rgba(102, 187, 106, 0.5);
    box-shadow: none;
  }

  svg {
    vertical-align: sub;
  }
}

.chatContainer {
  margin-left: 24px;

  @media only screen and (max-width: $minScreen) {
    margin-left: 0;
    padding-left: 0;
  }
}

.chatRow {
  margin-bottom: 24px;
  min-width: 270px;
}

.chatCol {
  padding: 0;

  .card {
    margin-bottom: 4px;
  }
}

.var-margin {
  @media screen and (max-height: 780px) {
    margin-top: 20px;
  }
  @media screen and (min-height: 781px) {
    margin-top: 100px;
  }
}