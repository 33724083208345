@import "src/styles/variables";

.loginCard {
  width: 90%;
  padding: 2rem 0;
  max-width: 800px;
  min-width: 270px;

  @media only screen and (max-width: $minScreen) {
    width: 80%;
    padding: 0;
  }

  .card-body {
    display: flex;
    align-self: center;
    flex-direction: column;
    width: 100%;
  }
}

.loginButton,
.loginButton:hover {
  min-width: 120px;
  background:#02071D !important;
  border:#02071D !important;
  border-radius: 4px;
  color: white;
}

.loginField {
  align-self: center;
  width: 40%;

  @media only screen and (max-width: $minScreen) {
    width: 80%;
    padding: 0;
  }
}