@import "src/styles/variables";

.feedStyles-notifications {
  width: 65%;

  .displayTime {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0px 0px 16px 0px;
    color: #000000;
    padding-top: 20px;
  }

  .displayAdherence {
    padding-top: 0px !important;
  }

  div:nth-child(n+3) {
    @media only screen and (max-width: 1200px) {
      margin-bottom: 14px;
    }
  }

  @media only screen and (max-width: 1200px) {
    margin-bottom: 20px;
    width: 100% !important;
  }

  @media only screen and (min-width: 700px) {
    margin-left: 24px;
  }
}