#sectionSpinner {
  color: #F17B77;
  text-align: center;
  width: 100%;
}

.section-dropdown {
  margin-bottom: 0.75rem;

  .dropdown-toggle {
    width: 100%;
  }

  .dropdown-menu {
    width: 100%;
  }
}

.save-button {
  margin-top: 1rem;
  width: 150px;
}