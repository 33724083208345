@import "src/styles/variables";

.feedStyles {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 24px;

  .input-group-append {
    position: relative;
    display: block;
    top: 76px;
    margin: 0px -10px 0px 0px;
  }

  #noteInputContainer {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    background: #ffffff;
    margin: 0px 0px 30px 0px;
  }

  #noteInputContainer textarea {
    border: none;
    resize: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin: 0px 0px 0px 0px;
  }

  #noteInputCard {
    width: 100% !important;
    margin-left: 0 !important;
  }

  @media screen and (min-width: 1390px) {
    #noteInputCard {
      width: 75% !important;
    }
  }

  @media only screen and (max-width: $minScreen) {
    flex-grow: 0;
    margin-left: 0;
  }

}