@import "src/styles/variables";

#mainContent {
  height: calc(100vh - 64px);
  //min-height: 100vh;
  padding: 4rem;
  display: flex;
  align-items: flex-start;

  @media only screen and (max-width: $minScreen) {
    flex-direction: column;
  }

  // Transition must match bmMenuWrap
  transition: all 0.25s ease .1s;
}