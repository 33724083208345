@import "../../../styles/variables.scss";

.modal-lg {
  max-width: 504px;
}

.modal-content {
  border-radius: 16px;

  .form-group {
    flex-direction: column;
  }

  .cancelBtn {
    background: #6C757D;
    border-color: #6C757D;
  }

  .cancelBtn:focus {
    background: rgb(176, 0, 32);;
    border-color: rgb(176, 0, 32);;
  }

  .cancelBtn:hover {
    background: #50565c;
    border-color: #50565c;
  }

  #fileUpload {
    input[type="file"] {
      position: absolute;
      opacity: 0;
      width: 100%;
      display: block;
    }

    label {
      width: 100%;
      height: 225px;
      background: rgba(0, 0, 0, 0.12);
      border: 1px solid rgba(0, 0, 0, 0.54);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 240px;
    }
  }

  .form-group {
    display: flex;
  }

  .modal-footer {
    padding: 0.75rem;
    justify-content: flex-start;
  }

  .modal-footer,
  .modal-header {
    border: 0;
  }

  .modal-title {
    font-size: 20px;
  }

  .modal-body {
    padding: 0 1rem 0 1rem;
  }

  #resourceSpinner {
    color: #F17B77;
    text-align: center;
    width: 100%;
  }

  .preview-image {
    margin: auto;
    max-height: 300px;
  }

  .error-message {
    color: #FF0000;
  }

  #textUpload {
    @media screen and (max-width: 500px) {
      textarea {
        width: 93vw;
      }
    }
  }

  @media screen and (max-width: 500px) {
    #fileUpload {
      width: 93vw;
    }
  }
}