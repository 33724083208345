.userNames {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 200px;
    
}

.containerScrollable {
    height: 135px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 24px;
    
    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  /* hide scrollbar for chrome, safari and opera */
  .containerScrollable::-webkit-scrollbar {
    display: none;
  }