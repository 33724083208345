@import "src/styles/variables";
.row {
    padding-left: 0;
    padding-right: 0;

    .col-md-6 {
        padding-left: 5px;
        padding-right: 5px;
    }
}


.clinicDisabledCard {
  overflow: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  margin: 0px 20px 20px 20px;
  // min-width: 300px;

  font-size: 16pt;
  font-weight: 500;
  color: rgb(241, 123, 119);
  background: rgb(221, 221, 221);
  justify-content: flex-start;
  /*
  margin-left: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  flex-basis: 322px;
  margin-bottom: 24px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  */

  .card-body {
    display: flex;
    justify-content: space-between;
  }

  div:nth-child(1) {
    align-self: flex-start !important;
    pointer-events: none;
  }

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.38);
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }

  .disabled-label {
    font-style: normal;
    font-weight: normal;
    font-size: 12.8px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0;
  }

  .disabled-id {
    font-style: normal;
    font-weight: normal;
    font-size: 12.8px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.38);
  }

  @media only screen and (max-width: $minScreen) {
    min-width: 300px;
    margin-left: 0;
  }

  @media only screen and (max-width: 667px) {
    min-width: 270px;
  }
}

.clinicEnabledCard {
  overflow: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  margin: 0px 20px 20px 20px;

  .clinic-body {
    padding: 0;
  
    .container {
      padding: 0;

      .enabled {
        display: flex;
        min-width: 300px;
        padding: 1rem 1rem 0.8rem 1rem;
      
        div:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      
      }

      
    }
  }

  .clinic-footer {
    .crud {
        justify-content: flex-end;
        margin-right: -10px;
  
        svg {
          margin: 0px 4px 0px 4px;
          cursor: pointer;
        }
      }
  }

  
  @media only screen and (max-width: 699px) {
    margin-left: 0;
  }
}

.untile {
  max-width: 70% !important;
  align-items: flex-start;

  @media only screen and (max-width: 1200px) {
    max-width: 100% !important;
  } 
}