@import "src/styles/variables";

.userDisabledCard {
  overflow: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  margin: 0px 20px 20px 20px;
  // width: 326px;
  /*
  margin-left: 24px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  flex-basis: 322px;
  margin-bottom: 24px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  */

  .card-body {
    display: flex;
    justify-content: space-between;
  }

  div:nth-child(1) {
    align-self: flex-start !important;
    padding: 0.70rem;
    pointer-events: none;
  }

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.38);
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }

  .disabled-label {
    font-style: normal;
    font-weight: normal;
    font-size: 12.8px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0;
  }

  .disabled-id {
    font-style: normal;
    font-weight: normal;
    font-size: 12.8px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.38);
  }

  @media only screen and (max-width: $minScreen) {
    min-width: 300px;
    margin-left: 0;
  }

  @media only screen and (max-width: 667px) {
    min-width: 270px;
  }
}

.userEnabledCard {
  overflow: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  margin: 0px 20px 20px 20px;

  .user-body {
    padding: 0;
  
    .container {
      padding: 0;

      .enabled {
        display: flex;
        // width: 355px;
        padding: 1rem 1rem 0.8rem 1rem;
      
        div:nth-child(2) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      
      }

      .user-footer {
        margin: 0;
        padding: 0;
        width: 100%;
        padding: 0.8rem 1rem 1rem 1rem;
        background: #FFBDBE;
        border-bottom-left-radius: 16px !important;
        border-bottom-right-radius: 16px !important;
      
        p {
          margin: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 12.8px;
          line-height: 19px;
          color: rgba(0, 0, 0, 0.87);
        }

        .col-2,
        .col-10 {
          align-self: center;
          text-align: left;
        }

        .col-10 {
          padding-top: 4px;
          padding-left: 0px;
        }
      }
    }
  }

  @media only screen and (max-width: 699px) {
    margin-left: 0;
  }
}

.untile {
  max-width: 70% !important;
  align-items: flex-start;

  @media only screen and (max-width: 1200px) {
    max-width: 100% !important;
  } 
}