@import "src/styles/variables";

.feedStyles {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 24px;

  @media only screen and (max-width: $minScreen) {
    flex-grow: 0;
    margin-left: 0;
  }
}