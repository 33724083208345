@import "src/styles/variables";

.adherenceStyles {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row !important;
  margin-left: 24px;
  align-items: flex-start;
  width: 65%;

  .adherence {
    margin-left: 24px;

    .card-body {
      padding: 0.6rem 2.2rem 1.8rem 2.2rem;
    }
  }

  #adherenceChart {
    width: 100% !important;
    padding-bottom: 1.2rem;
  }

  #userAdherence {
    margin-bottom: 0;
    width: 60%;
  }

  .card {
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  }

  .card-title {
    padding-top: 1.2rem;
  }

  .adherence .card-title h5 {
    font-weight: 500;
    line-height: 24px;
    font-size: 20px;
  }

  .card-body {
    padding: 1.8rem 1.8rem 1.8rem 1.8rem;
    width: 100%;
  }

  @media only screen and (max-width: $minScreen) {
    flex-grow: 0;
    margin-left: 0;
  }

  @media only screen and (max-width: 1360px) {
    .adherence {
      margin: 24px 0px 24px 0px;
      width: 100%;

      .card-body {
        width: auto;
      }
    }

    #userAdherence {
      flex-basis: 100%;
    }
  }

  @media only screen and (max-width: 699px) {
    width: 100%;
  }
}