.groupMessagesPageHeaderCardStyle {
    flex-grow: 1;
      border-radius: 16px;
      display: flex;
      font-size: 16px;
      min-width: 270px;
      // max-width: 65%;
      margin-bottom: 24px;
      background: white;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    
      .break {
        display: none;
      }
    
      .card-body {
        display: flex;
        flex-direction: row;
        justify-content: right;
        flex-grow: 4;
        align-items: center;
        padding: 1.1rem;
      }
    
      .item {
        flex-grow: 1;
        text-align: right;
      }
    
      .item svg {
        width: 50px;
        height: 50px;
      }
    
      .upload {
        flex-basis: 54%;
      }
    
      .modal-wrapper {
        flex-basis: 46%;
        display: flex;
        flex-direction: row;
      }
    
      @media screen and (max-width: 667px) {
        min-width: 270px;
      }
    
      @media screen and (max-width: 699px) {
        margin-left: 0 !important;
      }
    
      @media screen and (max-width: 860px) {
        .modal-wrapper {
          width: 100%;
          justify-content: space-evenly;
        }
      }
    
      @media screen and (max-width: 1000px) {
        .card-body {
          flex-direction: column;
          align-content: space-between;
        }
    
        .modal-wrapper {
          width: 75%;
        }
      }
    
      @media screen and (max-width: 1200px) {
        margin-top: 0;
        margin-left: 0;
        max-width: 100%;
      }
  }