@import "../../../styles/variables.scss";

.update {
    max-width: 65%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    margin-left: 24px;
    margin-bottom: 24px;

    .tags {
      color: #5C6BC0;
      margin-bottom: 0;
    }

    h5 {
      color: #ffffff;
      font-size: 1.3em;
    }

    .card-body {
      padding: 0;
    }

    .card-header {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-size: cover !important;
      height: 400px;
    }

    .col-auto {
      padding-top: 6px !important;
    }

    .update-row {
      margin: 15px 5px 10px 5px;
    }

    .p-top {
      padding-top: 20px;
    }

    .crud {
      margin: 0px 15px 15px 0px;
      justify-content: flex-end;

      svg {
        margin: 0px 4px 0px 4px;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 699px) {
      margin-left: 0;
    }

    @media only screen and (max-width: 1200px) {
      margin-top: 24px;
      max-width: 100%;
    }

    @media only screen and (max-width: 1320px) {
      .card-header {
        background-size: auto, 100%;
      }
    }
}