@import "src/styles/variables";

.sideNavContainer {
  width: 270px;
  min-width: 270px;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  background: #ffffff;

  @media only screen and (max-width: $minScreen) {
    align-self: normal;
  }
}

.sidebar-action-spinner {
  color: #F17B77;
  text-align: center;
  position: absolute;
  right: 20px;
  top: 10px;
}

.sideNavItem {
  height: 48px;
  padding-left: 16px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 0px;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    background-color: rgba(241, 123, 119, 0.15);
    color: rgb(241, 123, 119);
    cursor: pointer;

    svg {
      g {
        rect {
          fill: rgb(241, 123, 119);
        }
      }
    }
  }

  svg {
    g {
      rect {
        fill-opacity: 1;
        fill: rgba(0,0,0, 0.60);
      }
    }
  }
}

.sideNavText {
  margin-left: 16px;
  margin-bottom: 0;
}

.roundedBordersTop:hover {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.roundedBordersBottom:hover {
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
}

