#usage {
    max-width: 75%;
    margin-bottom: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    
    color: #000000;

    p {
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }

    .usage-content {
        text-align: right;
        align-self: center;

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 12.8px !important;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.54);
        }

        @media screen and (max-width: 920px) {
            flex-basis: 100%;
            margin-top: 10px;
            text-align: left;
        }
    }

    @media only screen and (max-width: 1200px) {
        margin-bottom: 20px;
        max-width: 100%;
      }
}