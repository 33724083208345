@import "src/styles/variables";

.userStyles {
  flex-grow: 1;
  border-radius: 16px;
  justify-content: left;
  flex-wrap: wrap;
  
  @media only screen and (max-width: $minScreen) {
    flex-grow: 0;
  }

  @media only screen and (max-width: 742px) {
    max-width: 100%;
  }
}

.infinite-scroll-component__outerdiv{
  width: 100%;
}

#userSpinner {
  color: #F17B77;
  margin-top: 10%;
  text-align: center;
  width: 100%;
}