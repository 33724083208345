@import "../../../styles/variables.scss";

.modal-lg {
  max-width: 504px;
}

.modal-content {
  border-radius: 16px;

  .form-group {
    flex-direction: column;
  }

  .cancelBtn {
    background: #6C757D;
    border-color: #6C757D;
  }

  .cancelBtn:focus {
    background: rgb(176, 0, 32);;
    border-color: rgb(176, 0, 32);;
  }

  .cancelBtn:hover {
    background: #50565c;
    border-color: #50565c;
  }

  .form-group {
    display: flex;
  }

  .modal-footer {
    padding: 0.75rem;
    justify-content: flex-start;
  }

  .modal-footer,
  .modal-header {
    border: 0;
  }

  .modal-title {
    font-size: 20px;
  }

  .modal-body {
    padding: 0 1rem 0 1rem;
  }

  #resourceSpinner {
    color: #F17B77;
    text-align: center;
    width: 100%;
  }

  .error-message {
    color: #FF0000;
  }
}