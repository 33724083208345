@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap');

.totalAdherenceContainer {
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  justify-content: center !important;
}

.CircularProgressbar {
  position: relative;
  width: 100%;
}

.CircularProgressbar-path {
  stroke: red !important;
  width: 20px !important;
}
.CircularProgressbar-trail {
  stroke: rgba(0, 0, 0, 0.12) !important;
}

.CircularProgressbar-text {
  font-family: "JetBrains Mono", monospace;
  fill: #000000 !important;
  transform: translateY(3px);
}

.red .CircularProgressbar-path {
  stroke: #F44336 !important;
}

.green .CircularProgressbar-path {
  stroke: #66BB6A !important;
}

.yellow .CircularProgressbar-path {
  stroke: #FFA726 !important;
}