#userEngagement {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    border-radius: 16px;
    padding: 0px 14px 0px 14px;
    margin-bottom: 0.6rem;
    max-width: 100% !important;

    .action-row {
        padding-top: 10px;
        margin-bottom: 0 !important;
    }

    .card-body {
        padding: 1.25rem 1.25rem 0.2rem 1.25rem;
    }

    h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin: 0rem 0rem 0.5rem 0rem;
    }

    .message-user {
        padding-left: 0px;
        color: #5C6BBF;
        cursor: pointer;
        text-decoration: underline;
    }

    .notification-box {
        margin: 6px 0px 0px 14px;
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 12.8px;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.6);
    }

    .reason {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
        color: rbga(0, 0, 0, 0.6);
    }

    .reason-title {
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        color: rgba(195, 91, 97, 1);

    }
    
    .status {
       text-align: right;
       padding-right: 0px;

    }

    .status p {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
    }

    .status-block {
        display: none;
    }

    .status-skipped {
        color: rgba(0, 0, 0, 0.87);
    }

    .status-confirmed {
        color: #5C6BC0;
    }

    .status-received {
        color: rgba(0, 0, 0, 0.38);
    }

    svg {
        width: 56px;
        height: 56px;
    }
}