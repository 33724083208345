@import "src/styles/variables";

.displayNotesCardContainer {
    
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
    margin-bottom: 20px;

    .dt {
        text-align: left !important;
        padding: 0 16px 0 0;
    }

    h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    h6 {
        font-style: normal;
        font-weight: normal;
        font-size: 12.8px;
        line-height: 19px;
        color: rgba(0, 0, 0, 0.54);
        margin: 4px 0px 0px 0px;
    }

    .note {
        padding: 0px 0px 0px 10px;
    }

    .note-box {
        margin: 6px 0px 0px 12px;

        p {
            margin-bottom: 0px;
        }
    }

    .au {
        padding: 0px 0px 0px 0px;
    }

    @media screen and (min-width: 576px) {
        .dt {
            text-align: right !important;
            padding: 0 16px 0 15px;
        }
    }

    @media screen and (min-width: 1390px) {
        width: 75%;
    }

    @media screen and (max-width: 900px) {
        .wrap-r {
            display: inline-block !important;
        }

        .dt,
        .au {
            max-width: 100% !important;
        }

        .dt {
            text-align: left !important;
            padding: 0 16px 0 0;
        }
    }
}